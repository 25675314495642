.ToastContainer {
	box-sizing: border-box;
}

.ToastList {
	box-sizing: border-box;
	position: fixed;
	bottom: 1rem;
	right: 2rem;
	left: auto;
	z-index: 1000;
	line-height: 1.5;
	text-align: right;
	transform: translateX(0) translateZ(0);
}

.ToastMessage {
	opacity: 0;
	margin-bottom: 0.5rem;
	font-size: 0;
	transition:
		height 0.3s,
		opacity 0.3s,
		transform 0.3s;
}

.ToastContent {
	display: flex;
	flex-direction: row;
	place-content: center center;
	overflow: hidden;
	min-width: 18rem;
	padding: 1rem 2rem;
	box-shadow: 1px 2px 5px 1px rgb(0 0 0 / 20%);
	border-radius: var(--border-radius-md);
	color: #fff;
	font-size: 1rem;
	text-align: center;
	line-height: 1.5;
}

.ToastSuccess {
	background-color: var(--success-color);
}

.ToastError {
	background-color: var(--error-color);
}

.ToastInfo {
	background-color: var(--info-color);
}

.ToastText {
	margin-left: 1rem;
}

.Icon {
	margin-top: 0.2rem;
}

.ToastEnter {
	height: 0;
}

.ToastEnterActive {
	opacity: 1;
}

.ToastEnterDone {
	opacity: 1;
}

.ToastExitActive {
	transform: translateY(-0.65rem);
}
