.ImageContainer {
	position: relative;
	display: inline-block;
	overflow: hidden;
}

.ImageContainer > div {
	/* position: unset !important; */
}

.Image {
	position: relative !important;
	padding: 0 !important;
	margin: 0 !important;
	height: 100%;
	width: 100%;
	inset: 0 !important;
	color: transparent;
}
