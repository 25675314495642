.NavLinkRow {
	padding: 0.5rem;
	margin-top: 0.5rem;
	border-radius: var(--border-radius-md);
}

.NavLinkColumn {
	padding: 0.6875rem;
	margin-top: 0.875rem;
	border-radius: var(--border-radius-md);
}

.NavLinkRow:hover {
	background: var(--nav-color-active);
}

.NavLinkColumn:hover {
	background: var(--color-purple-2);
}

.NavLinkText {
	color: var(--nav-color-text) !important;
}

.NavLinkDisabledText {
	color: var(--nav-disabled-color-text) !important;
}

.NavLinkTextContainer {
	width: 100%;
}

.NavLinkActive {
	font-weight: var(--weight-bold);
	background: var(--nav-color-active);
}

.NavLinkActiveColumn {
	background: var(--color-purple-2);
}

.NavLinkDisabled {
	cursor: pointer;
	padding: 0.5rem;
	margin-top: 0.5rem;
	border-radius: var(--border-radius-md);
	transition: 0.3s;
}

.NavLinkDisabled:hover {
	background: rgb(151 161 252 / 25%);
}

.NavLink > * {
	margin-right: 0.25rem;
}

.IconSizeSm {
	width: 0.5rem;
	height: 0.5rem;
}

.IconSizeMd {
	width: 1rem;
	height: 1rem;
}

.IconSizeLg {
	height: 1.875rem !important;
}

.IconDisabled {
	color: var(--nav-disabled-color-text) !important;
}
