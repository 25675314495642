.UserMenu {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
}

.DropdownWrapper {
	position: relative;
	width: 100%;
}

.DropdownContent {
	width: 10rem;
	position: absolute;
	bottom: 0.1rem;
	left: 14rem;
	z-index: 100;
	box-shadow: var(--container-shadow);
	border-radius: var(--border-radius-md);
	background: var(--nav-color-background);
	border: 1px solid var(--color-violet-4);
	padding: 0.5rem;
}

.DropdownContentItems > * {
	width: 100%;
	box-sizing: border-box;
	padding: var(--spacing-1);
	border-radius: 0.25rem;
}

.DropdownContentItems > *:hover {
	background-color: var(--nav-color-active);
}

.SignOut {
	border: none;
	background-color: var(--nav-color-background);
	padding: 0;
}
