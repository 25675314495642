.SidebarNav {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	width: 240px;
	box-sizing: border-box;
}

.SidebarNavColumn {
	padding: var(--spacing-4);
	width: 11.25rem;
}

.SidebarNavColumn div:last-child {
	margin-top: auto;
}

.SidebarNavRow > hr,
.SidebarNavColumn > hr {
	margin: var(--spacing-xs) 0;
	border-color: var(--nav-color-separator);
}

.SubItems {
	display: flex;
	flex-direction: column;
	margin-left: 1rem;
}

.Logo {
	padding: 0.25rem;
	height: 2.8rem;
	width: 2.8rem;
	margin-bottom: var(--spacing-md);
}

.LogoColumn {
	height: 3.45rem;
}

.Children {
	margin-top: 1rem;
	padding: 0.5rem;
}

.HeadingText {
	margin-top: 1rem;
	color: var(--nav-color-text) !important;
}
