.App {
	height: 100%;
	display: grid;
	grid-template-columns: var(--shell-sidenav-width) 1fr;
	background: var(--app-color-background);
}

.AppSingleColumn {
	display: block;
	grid-template-columns: 1fr;
}

.Body {
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	height: inherit;
}

.AppContent {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	max-width: 1440px;
}

.ContentSingleColumn {
	max-width: none;
	width: 100%;
}

.ContentWithBottomNav {
	overflow-y: scroll;
}

.Nav {
	background: var(--nav-color-background);
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-start;
}

.NavColumn {
	justify-content: flex-start;
}

.MobileNav {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 20rem;
	padding: 1rem 1rem;
	transform: translateX(-100%);
}

.MobileNavMenuOpen {
	transform: translateX(0);
	transition: all 0.5s var(--ease-out-quad);
}

.MobileNavMenuClosed {
	transform: translateX(-100%);
	transition: all 0.5s var(--ease-out-quad);
}

.MobileNavWrapper {
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgb(0 0 0 / 60%);
}

@media (max-width: 768px) {
	.App {
		display: block;
	}
}

@media (min-width: calc(1440px + 280px)) {
	.App {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.Body {
		flex-grow: 1;
	}

	.NavContainer {
		display: flex;
		justify-content: flex-end;
		flex-grow: 1;
	}

	.NavContainerColumn {
		flex-grow: 0;
	}

	.AppContent {
		width: 1440px;
	}

	.ContentSingleColumn {
		width: 100%;
	}
}
