.Stack {
	display: flex;
}

.StackFullWidth {
	width: 100%;
}

.StackFullHeight {
	height: 100%;
}

.StackDirectionRow {
	flex-direction: row;
}

.StackDirectionRowReverse {
	flex-direction: row-reverse;
}

.StackDirectionColumn {
	flex-direction: column;
}

.StackDirectionColumnReverse {
	flex-direction: column-reverse;
}

.StackJustifyContentFlexEnd {
	justify-content: flex-end;
}

.StackJustifyContentFlexStart {
	justify-content: flex-start;
}

.StackJustifyContentCenter {
	justify-content: center;
}

.StackJustifyContentSpaceBetween {
	justify-content: space-between;
}

.StackJustifyContentSpaceAround {
	justify-content: space-around;
}

.StackAlignItemsNormal {
	align-items: normal;
}

.StackAlignItemsCenter {
	align-items: center;
}

.StackAlignItemsFlexStart {
	align-items: flex-start;
}

.StackAlignItemsFlexEnd {
	align-items: flex-end;
}

.StackAlignItemsStretch {
	align-items: stretch;
}

.StackAlignItemsBaseline {
	align-items: baseline;
}

.StackSpacingNone {
	gap: 0;
}

.StackSpacingXxs {
	gap: var(--spacing-xxs);
}

.StackSpacingXs {
	gap: var(--spacing-xs);
}

.StackSpacingSm {
	gap: var(--spacing-sm);
}

.StackSpacingMd {
	gap: var(--spacing-md);
}

.StackSpacingLg {
	gap: var(--spacing-lg);
}

.StackSpacingXl {
	gap: var(--spacing-xl);
}

.StackWrapNoWrap {
	flex-wrap: nowrap;
}

.StackWrapWrap {
	flex-wrap: wrap;
}

.StackWrapNoWrapReverse {
	flex-wrap: wrap-reverse;
}
