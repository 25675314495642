.NavContainer {
	height: 60px;
	box-sizing: border-box;
	display: flex;
}

.NavContainerSticky {
	top: 0;
	width: 100%;
	position: sticky;
	z-index: 100;
	transition: all 0.4s;
}

.NavContainerCentered {
	justify-content: center;
}

.Nav > *:last-child {
	flex: 1;
}

.NavContainerActive {
	background: white;
}

.Nav {
	display: flex;
	padding: 0.6rem 2rem;
	box-sizing: border-box;
	width: 100%;
	align-items: center;
}
