.LinkColorPrimary {
	color: var(--link-color-primary);
}

.LinkColorPrimary:hover {
	color: var(--link-hover-color-primary);
}

.LinkColorPrimary:active {
	color: var(--link-active-color-primary);
}

.LinkColorDestructive {
	color: var(--link-destructive-color);
}

.LinkColorDestructive:hover {
	color: var(--link-hover-destructive-color);
}

.LinkColorDestructive:active {
	color: var(--link-active-destructive-color);
}

.Active {
	border-bottom: 1px solid var(--link-color-primary);
	color: var(--link-color-primary);
}

.Disabled {
	pointer-events: none;
	color: var(--link-disabled-color);
}
