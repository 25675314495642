.Avatar {
	line-height: 3.125rem;
	height: 3.125rem;
	width: 3.125rem;
	min-height: 3.125rem;
	min-width: 3.125rem;
	text-align: center;
	font-size: 1.25rem;
	background: var(--primary-color-lightest);
	color: var(--primary-color);
	object-fit: contain;
}

.AvatarSizeSmall {
	line-height: 2.4rem;
	height: 2.5rem;
	width: 2.5rem;
	min-height: 2.5rem;
	min-width: 2.5rem;
	font-size: 1.25rem;
}

.AvatarSizeLarge {
	line-height: 5rem;
	height: 5rem;
	width: 5rem;
	min-height: 5rem;
	min-width: 5rem;
	font-size: 2rem;
	font-weight: var(--weight-bold);
}

.AvatarIcon {
	color: white;
	background: var(--primary-color);
}

.AvatarColorPrimary {
	color: var(--avatar-primary-color);
	background: var(--avatar-primary-background-color);
}

.AvatarColorSecondary {
	color: var(--avatar-secondary-color);
	background: var(--avatar-secondary-background-color);
}

.AvatarColorDarkPurple {
	color: var(--color-purple-2);
	background-color: var(--color-purple-10);
}

.AvatarColorPink {
	color: var(--color-white);
	background: var(--color-pink-4);
}

.AvatarBorderRadiusCircle {
	border-radius: 50%;
}

.AvatarBorderRadiusSquared {
	border-radius: 0.5rem;
}
