.ParagraphTypeBody {
	font-family: var(--font-family);
	font-size: var(--scale-00);
	letter-spacing: var(--letter-xl);
	font-weight: var(--weight-medium);
}

.ParagraphTypeBody2 {
	font-family: var(--font-family);
	font-size: var(--scale-0000);
	letter-spacing: var(--letter-xl);
	font-weight: var(--weight-regular);
}

.ParagraphTypeSubtitle {
	font-family: var(--font-family);
	font-size: var(--scale-000);
}

.SubtitleWeightMedium {
	font-weight: var(--weight-medium);
}

.SubtitleWeightSemibold {
	font-weight: var(--weight-semibold);
}

.ParagraphTypeSubtitle2 {
	font-family: var(--font-family);
	font-size: var(--scale-0000);
}

.Subtitle2WeightMedium {
	font-weight: var(--weight-medium);
}

.Subtitle2WeightSemibold {
	font-weight: var(--weight-semibold);
}

.ParagraphTypeCaption {
	font-family: var(--font-family);
	font-size: var(--scale-00000);
	font-weight: var(--weight-semibold);
	line-height: 1.25rem;
}
