.ButtonDefault {
	display: inline-block;
	border-radius: var(--button-border-radius);
	text-decoration: none;
	transition: 0.3s;
	border: 0;
	width: inherit;
}

.ButtonDefault:disabled {
	background-color: var(--button-color-disabled) !important;
	color: var(--button-text-color-disabled) !important;
}

.ButtonFullWidth {
	width: var(--button-width-full);
}

.ButtonPaddingSm {
	padding: var(--button-padding-sm);
}

.ButtonPaddingMd {
	padding: var(--button-padding-md);
}

.ButtonThick {
	font-size: 1.125rem;
	line-height: 1.125rem;
	padding: var(--button-padding-thick);
}

.ButtonIcon {
	width: 2rem;
	height: 2rem;
	border-radius: var(--button-border-radius-icon);
	padding: 0;
}

.ButtonIcon:active {
	background-color: var(--button-active-color-icon);
}

.ButtonColorPrimary {
	background-color: var(--button-color-primary);
	color: var(--button-text-color-primary);
}

.ButtonColorPrimary:hover {
	background-color: var(--button-hover-color-primary);
}

.ButtonColorPrimary:active {
	background-color: var(--button-active-color-primary);
}

.ButtonColorSecondary {
	background-color: var(--button-color-secondary);
	border: solid 1px var(--button-border-color-secondary);
	color: var(--button-text-color-secondary);
}

.ButtonColorSecondary:hover {
	background-color: var(--button-color-secondary);
	border: solid 1px var(--button-border-hover-color-secondary);
}

.ButtonColorSecondary:active {
	background-color: var(--button-color-secondary);
	border: solid 1px var(--button-border-active-color-secondary);
}

.ButtonColorGhost {
	background-color: var(--button-color-ghost);
	border: solid 1px var(--button-border-color-ghost);
	color: var(--button-text-color-ghost);
}

.ButtonColorGhost:hover {
	background-color: transparent;
	border: solid 1px var(--button-hover-color-ghost);
}

.ButtonColorGhost:active {
	background-color: transparent;
	border: solid 1px var(--button-active-color-ghost);
}

.ButtonColorDestructive {
	background-color: var(--button-color-destructive);
	border: solid 1px var(--button-border-color-destructive);
	color: var(--button-text-color-destructive);
}

.ButtonColorDestructive:hover {
	background-color: var(--button-hover-color-destructive);
}

.ButtonColorDestructive:active {
	background-color: var(--button-active-color-destructive);
}

.ButtonColorSuccess {
	background-color: var(--button-color-success);
	color: var(--button-text-color-sucess);
}

.ButtonColorSuccess:hover {
	background-color: var(--button-active-color-success);
}

.ButtonColorSuccess:active {
	background-color: var(--button-active-color-success);
}

.ButtonColorWarning {
	background-color: var(--button-color-warning);
	color: var(--button-text-color-warning);
}

.ButtonColorWarning:hover {
	background-color: var(--button-hover-color-warning);
}

.ButtonColorWarning:active {
	background-color: var(--button-active-color-warning);
}

.ButtonColorIcon {
	background-color: var(--button-color-icon);
	color: var(--button-text-color-icon);
	border: 1px solid var(--button-border-color-icon);
}

.ButtonColorIcon:hover {
	background-color: var(--button-hover-color-icon);
}
