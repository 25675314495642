.TypographySpacingSm {
	margin-bottom: var(--spacing-xxs);
}

.TypographySpacingMd {
	margin-bottom: var(--spacing-xs);
}

.TypographySpacingLg {
	margin-bottom: var(--spacing-sm);
}

.TypographySpacingXl {
	margin-bottom: var(--spacing-md);
}

.TypographySpacingNone {
	margin-bottom: 0;
}

.TypographyAlignLeft {
	text-align: left;
}

.TypographyAlignCenter {
	text-align: center;
}

.TypographyAlignRight {
	text-align: right;
}

.TypographyBold {
	font-weight: var(--weight-bold);
}

.TypographyUnderline {
	text-decoration: underline;
}

.TypographyPreserveFormat {
	white-space: pre-line;
}

.TypographyStrikeThrough {
	text-decoration: line-through;
}

.TypographyTextWrapNormal {
	overflow-wrap: normal;
}

.TypographyTextWrapBreakWord {
	overflow-wrap: break-word;
}

.TypographyTextWrapAnywhere {
	overflow-wrap: anywhere;
}

.TypographyTextWrapNowrap {
	text-wrap: nowrap;
}

.TypographyTextOverflowEllipsis {
	text-overflow: ellipsis;
	overflow: hidden;
}

.TypographyTextOverflowClip {
	text-overflow: clip;
	overflow: hidden;
}

.TypographyWordBreakBreakAll {
	word-break: break-all;
}

.TypographyWordBreakBreakWord {
	/* stylelint-disable-next-line declaration-property-value-keyword-no-deprecated */
	word-break: break-word;
}

.TypographyColorPrimary {
	color: var(--typography-color-primary);
}

.TypographyColorRed {
	color: var(--color-red);
}

.TypographyColorOrange {
	color: var(--color-orange);
}

.TypographyColorYellow {
	color: var(--color-yellow);
}

.TypographyColorGreen {
	color: var(--color-green-dark);
}

.TypographyColorBlue {
	color: var(--color-blue);
}

.TypographyColorBlurple {
	color: var(--color-blurple);
}

.TypographyColorPink {
	color: var(--color-pink-light);
}

.TypographyColorGrey {
	color: var(--color-grey-light);
}

.TypographyColorWhite {
	color: white;
}

.TypographyColorBlack {
	color: var(--base-color-black);
}
