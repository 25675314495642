.HeadingH1 {
	font-family: var(--font-family);
	font-size: var(--scale-6);
	font-weight: var(--weight-bold);
	letter-spacing: var(--letter-none);
	line-height: var(--line-xxl);
}

.HeadingH2 {
	font-family: var(--font-family);
	font-size: var(--scale-4);
	font-weight: var(--weight-bold);
	letter-spacing: var(--letter-none);
	line-height: var(--line-xl);
}

.HeadingH3 {
	font-family: var(--font-family);
	font-size: var(--scale-3);
	font-weight: var(--weight-bold);
	letter-spacing: var(--letter-none);
	line-height: var(--line-lg);
}

.HeadingH4 {
	font-family: var(--font-family);
	font-size: var(--scale-1);
	font-weight: var(--weight-semibold);
	line-height: var(--line-md);
}

.HeadingH5 {
	font-family: var(--font-family);
	font-size: var(--scale-0);
	font-weight: var(--weight-medium);
	line-height: var(--line-sm);
}

.HeadingH6 {
	font-family: var(--font-family);
	font-size: var(--scale-00);
	font-weight: var(--weight-medium);
	line-height: var(--line-sm);
}

.WeightMedium {
	font-weight: var(--weight-medium);
}

.WeightSemibold {
	font-weight: var(--weight-semibold);
}

.WeightBold {
	font-weight: var(--weight-bold);
}

.Label {
	font-family: var(--font-family);
	font-size: var(--scale-0);
	color: var(--primary-color-dark);
}
